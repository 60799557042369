import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Page from './components/layout/Page';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Page/>
);

